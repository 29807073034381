import styled from 'styled-components'
import Cleave from 'cleave.js/react'
import { NumericFormat } from 'react-number-format'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    zIndex: 5,
    overflow: 'visible',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  max-width: 640px;
  background-color: #F7F7F7;
  padding: 10px;
  border-radius: 15px;
`

export const FormField = styled(Container)`
  flex: ${props => props.stretch ? props.stretch : 1};
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  margin: 0px;
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  gap: 10px;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: flex-start;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 16px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-weight: bold;
  margin-bottom: 10px;
`

export const SubTitle = styled(Title)`
  font-size: 16px;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin-bottom: 1px;

  &:hover {
    cursor: pointer;
  }
`

export const MoneyText = styled(SubTitle)`
  color: #44CF6C;
`

export const MoneyTextMask = styled.p`
  color: #44CF6C;
  font-size: 18px;
  font-weight: bold;
  margin-top: 9px;
  margin-bottom: 0px;
  padding: 0px;
`

export const WarningText = styled.p`
  margin: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 200;
  color: #FF3939;
`

export const ErrorContainer = styled.div`
  padding: 8px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 15px;
  text-align: center;
  background-color: #FF3939;

  p {
    color: #FFFFFF;
    margin: 0px;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const MaskedInput = styled(Cleave)`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const NumberInput = styled(NumericFormat).attrs({
  customInput: Input,
  thousandSeparator: '.',
  decimalSeparator: ',',
  displayType: 'input',
  allowNegative: false
})``

export const Button = styled.button`
  width: 200px;
  align-self: center;
  background-color: #252525;
  color: #FFFFFF;
  padding: 8px 20px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }
`

export const CloseButton = styled.button`
  position: fixed;
  top: 3px;
  right: 10px;
  width: 30px;
  height: 38px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`

export const DangerButton = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-decoration: none;
  margin-left: 7px;
  border: 0px;
  color: #FF3939;
  background-color: transparent;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`
