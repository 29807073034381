import React, { useState, useEffect } from 'react'
import { DndContext, useSensors, useSensor, PointerSensor } from '@dnd-kit/core'
import cutileiApi from '../../services/cutileiApi'
import AuthService from '../../services/auth'
import General from '../../services/General'
import CreateScheduleModal from '../../pages/Modals/CreateScheduleModal'
import ScheduleDetailsModal from '../../pages/Modals/ScheduleDetailsModal'
import EditReservationModal from '../../pages/Modals/EditReservationModal'
import EditBillModal from '../../pages/Financial/EditBillModal'
import OptionDialog from '../OptionDialog'
import AlertDialog from '../AlertDialog'
import Professional from './Professional'
import TimeBlock from './TimeBlock'
import Schedule from '../Schedule'
import { DateTime } from 'luxon'

import {
  Container,
  ScheduleGrid,
  ProfessionalsRow,
  TimeGrid,
  LinkGrid,
  TimeMark,
  InfoText,
  ProfessionalColumn
} from './styles'

function SchedulesTable ({
  professionals,
  schedules,
  timeWindows,
  timeInterval,
  date,
  numRows,
  numColumns,
  labels,
  query,
  onScheduleCreate: handleScheduleCreated,
  onEditSchedule:  handleEditSchedule,
  onDeleteSchedule: handleDeleteSchedule,
  onDeleteAllSchedules: handleDeleteAllSchedules,
  onEditScheduleStatus:  handleEditSchedulesStatus,
  onRemoveProfessional: handleRemoveProfessional,
}) {
  const [organizedSchedules, setOrganizedSchedules] = useState ([])
  const [showScheduleModal, setShowScheduleModal] = useState (false)
  const [showScheduleDetailsModal, setShowScheduleDetailsModal] = useState (false)
  const [showEditReservationModal, setShowEditReservationModal] = useState (false)
  const [showEditBillModal, setShowEditBillModal] = useState (false)
  const [showEditScheduleDialog, setShowEditScheduleDialog] = useState (false)
  const [showEditReservationDialog, setShowEditReservationDialog] = useState (false)
  const [createScheduleData, setCreateScheduleData] = useState (null)
  const [scheduleData, setScheduleData] = useState (null)
  const [editScheduleData, setEditScheduleData] = useState (null)
  const [reservationData, setReservationData] = useState (null)
  const [billData, setBillData] = useState (null)
  const [errorMessage, setErrorMessage] = useState (null)
  const selectedDate = DateTime.fromJSDate (date)
  const previousDayISODate = selectedDate.minus ({days: 1}).toISODate ()
  const nextDayISODate = selectedDate.plus ({days: 1}).toISODate ()
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  const sensors = useSensors (useSensor (PointerSensor, {
    activationConstraint: {
      distance: 3
    }
  }))

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    organizeSchedules ()
  }, [schedules])

  const organizeSchedules = () => {
    const schedulesByProfessional = General.groupBy (schedules.map (schedule => ({
      ...schedule, professional_id: schedule.professional.id
    })), 'professional_id').sort (compareSchedules)

    setOrganizedSchedules (schedulesByProfessional)
  }

  const handleDragEnd = event => {
    const { schedule, type } = event.active.data.current
    const { time, professional } = event.over.data.current

    setEditScheduleData ({...schedule, type, time, professional})

    if (type === 'schedule') toggleEditScheduleDialog ()
    else if (type === 'reservation') toggleEditReservationDialog ()
  }

  const editSchedule = async () => {
    const scheduleValues = {
      date: editScheduleData.date,
      time: editScheduleData.time,
      customerId: editScheduleData.customer.id,
      businessId: editScheduleData.business.id,
      serviceId: editScheduleData.service.id,
      professionalId: editScheduleData.professional.id,
      price: editScheduleData.price
    }

    try {
      const { data: schedule } = await cutileiApi.put (
        `/schedules/${editScheduleData.id}`, scheduleValues, requestConfig
      )

      handleEditSchedule (schedule)
    } catch (error) {
      console.log (error.response.data)
      if (error.response.data) setErrorMessage (error.response.data.message)
    }
  }

  const editReservation = async () => {
    try {
      const startTime = DateTime.fromFormat (editScheduleData.start_time, 'H:mm')
      const endTime = DateTime.fromFormat (editScheduleData.end_time, 'H:mm')
      const selectedTime = DateTime.fromFormat (editScheduleData.time, 'H:mm')
      const timePeriod = endTime.diff (startTime, 'minutes').toObject ().minutes

      const { data: reservationData } = await cutileiApi.put (`/schedule_reservations/${editScheduleData.id}`, {
        professionalId: editScheduleData.professional.id,
        startTime: editScheduleData.time,
        endTime: selectedTime.plus ({minutes: timePeriod}).toFormat ('H:mm'),
        weekdays: editScheduleData.weekdays
      }, requestConfig)

      handleEditSchedule (reservationData)
    } catch (error) {
      if (error.response?.status === 403)
        setErrorMessage (error.response.data.message)
      console.log (error)
    }
  }
  
  const editReservationDay = async () => {
    const reservationValues = {
      businessId,
      professionalId: editScheduleData.professional_id,
      description: editScheduleData.description,
      startDate: editScheduleData.start_date,
      endDate: editScheduleData.end_date,
      startTime: editScheduleData.start_time,
      endTime: editScheduleData.end_time,
      weekdays: editScheduleData.weekdays
    }

    const startTime = DateTime.fromFormat (reservationValues.startTime, 'H:mm')
    const endTime = DateTime.fromFormat (reservationValues.endTime, 'H:mm')
    const selectedTime = DateTime.fromFormat (editScheduleData.time, 'H:mm')
    const timePeriod = endTime.diff (startTime, 'minutes').toObject ().minutes
    const selectedISODate = selectedDate.toISODate ()

    try {
      await cutileiApi.post ('/schedule_reservations', {
        ...reservationValues,
        ...(selectedISODate === reservationValues.startDate
          ? {startDate: nextDayISODate}
          : {endDate: previousDayISODate}
        )
      }, requestConfig)

      if (![reservationValues.startDate, reservationValues.endDate].includes (selectedISODate))
        cutileiApi.post ('/schedule_reservations', {
          ...reservationValues,
          startDate: nextDayISODate
        }, requestConfig)

      const { data: reservationData } = await cutileiApi.put (`/schedule_reservations/${editScheduleData.id}`, {
        professionalId: editScheduleData.professional.id,
        startDate: selectedISODate,
        endDate: selectedISODate,
        startTime: editScheduleData.time,
        endTime: selectedTime.plus ({minutes: timePeriod}).toFormat ('H:mm'),
        weekdays: editScheduleData.weekdays
      }, requestConfig)

      handleEditSchedule (reservationData)
    } catch (error) {
      if (error.response?.status === 403)
        setErrorMessage (error.response.data.message)
      console.log (error)
    }
  }

  const compareSchedules = (a, b) => a[0].professional.number - b[0].professional.number
  const toggleScheduleModal = () => setShowScheduleModal (!showScheduleModal)
  const toggleScheduleDetailsModal = () => setShowScheduleDetailsModal (!showScheduleDetailsModal)
  const toggleEditReservationModal = () => setShowEditReservationModal (!showEditReservationModal)
  const toggleEditBillModal = () => setShowEditBillModal (!showEditBillModal)
  const toggleEditScheduleDialog = () => setShowEditScheduleDialog (!showEditScheduleDialog)
  const toggleEditReservationDialog = () => setShowEditReservationDialog (!showEditReservationDialog)
  
  return (
    <Container>
      <ProfessionalsRow numColumns={numColumns}>
        {professionals.map (professional => (
          <Professional
            key={professional.id}
            data={professional}
            onRemove={handleRemoveProfessional}
          />
        ))}
      </ProfessionalsRow>
      <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
        <ScheduleGrid numRows={numRows} numColumns={numColumns}>
          <TimeGrid numRows={numRows} numColumns={numColumns}>
            {labels.map ((label, index) => (
              <TimeMark
                key={index}
                position={index + 1}
                numRows={numRows}
                label={label}
              />
            ))}
          </TimeGrid>
          <LinkGrid numRows={numRows} numColumns={numColumns}>
            {query === '' && professionals.map ((professional, professionalIndex) => {
              return labels.map ((label, labelIndex) => (
                labelIndex < labels.length -1 && (
                  <TimeBlock
                    key={professionalIndex + labelIndex}
                    id={`${professionalIndex}-${labelIndex}`}
                    professional={professional}
                    numColumns={numColumns}
                    positionx={professionalIndex}
                    positiony={labelIndex}
                    label={label}
                    onClick={() => {
                      setCreateScheduleData ({professional, date: selectedDate, time: label})
                      toggleScheduleModal ()
                    }}
                  />
                )
              ))
            })}
          </LinkGrid>
          {professionals.map ((professional, i) => {
            const professionalSchedules = organizedSchedules.find (scheduleList =>
              scheduleList[0].professional.id === professional.id
            ) ?? []
            
            return (
              <ProfessionalColumn
                key={i}
                numRows={numRows} 
                timeWindows={timeWindows}
                schedules={professionalSchedules}
              >
                {professionalSchedules.map ((schedule, index) => (
                  <Schedule
                    key={schedule.id}
                    data={schedule}
                    numColumns={numColumns}
                    timeWindows={timeWindows}
                    timeInterval={timeInterval}
                    schedules={professionalSchedules}
                    position={index + 2}
                    onClick={() => {
                      const isReservation = schedule.validated === undefined
                      if (isReservation) {
                        setReservationData (schedule)
                        toggleEditReservationModal ()
                      } else {
                        setScheduleData (schedule)
                        toggleScheduleDetailsModal ()
                      }
                    }}
                  />
                ))}
              </ProfessionalColumn>
            )
          })}
        </ScheduleGrid>
      </DndContext>
      {createScheduleData && (
        <CreateScheduleModal
          visible={showScheduleModal}
          data={createScheduleData}
          professionals={professionals}
          onConfirm={handleScheduleCreated}
          onClose={toggleScheduleModal}
        />
      )}
      {scheduleData && (
        <>
          <ScheduleDetailsModal
            visible={showScheduleDetailsModal}
            data={scheduleData}
            schedules={schedules}
            onEdit={schedule => {
              setScheduleData (schedule)
              handleEditSchedule (schedule)
              toggleScheduleDetailsModal ()
            }}
            onEditStatus={schedules => {
              handleEditSchedulesStatus (schedules)
              toggleScheduleDetailsModal ()
            }}
            onDelete={handleDeleteSchedule}
            onDeleteAll={handleDeleteAllSchedules}
            onAddSchedule={customer => {
              setCreateScheduleData ({date: selectedDate, customer})
              toggleScheduleDetailsModal ()
              toggleScheduleModal ()
            }}
            onClose={toggleScheduleDetailsModal}
            onOpenBill={bill => {
              setBillData (bill)
              setScheduleData ({...scheduleData, bill_id: bill.id})
              handleEditSchedule ({...scheduleData, bill_id: bill.id})
              toggleScheduleDetailsModal ()
              toggleEditBillModal ()
            }}
            onEditBill={bill => {
              setBillData (bill)
              toggleScheduleDetailsModal ()
              toggleEditBillModal ()
            }}
          />
          {billData && (
            <EditBillModal
              visible={showEditBillModal}
              data={billData}
              onClose={toggleEditBillModal}
              onDeleteSchedule={handleDeleteSchedule}
              onEditScheduleStatus={handleEditSchedulesStatus}
              onCloseBill={() => {
                setScheduleData ({...scheduleData, status: 'paid', validated: true})
                handleEditSchedule ({...scheduleData, status: 'paid', validated: true})
              }}
            />
          )}
        </>
      )}
      {reservationData && (
        <EditReservationModal
          visible={showEditReservationModal}
          date={selectedDate}
          data={reservationData}
          onConfirm={handleEditSchedule}
          onDelete={handleDeleteSchedule}
          onClose={toggleEditReservationModal}
        />
      )}
      {editScheduleData && (() => {
        const { professional } = editScheduleData
        const professionalName = professional.nickname || professional.name.split (' ')[0]
        const isOneDayPeriod = editScheduleData.start_date === editScheduleData.end_date

        return (
          <>
            <AlertDialog
              visible={showEditScheduleDialog}
              title='Atenção!'
              message={
                <InfoText>
                  Deseja realmente alterar este agendamento para as
                  <b> {editScheduleData.time}</b> com <b>{professionalName}</b>?
                </InfoText>
              }
              confirmText='Sim'
              cancelText='Não'
              onConfirm={editSchedule}
              onClose={toggleEditScheduleDialog}
              containerStyle={{width: 360}}
            />
            <OptionDialog
              visible={showEditReservationDialog}
              title='Atenção!'
              message={
                isOneDayPeriod ? (
                  <InfoText>
                    Deseja realmente alterar esta reserva para as
                    <b> {editScheduleData.time}</b> com <b>{professionalName}</b>?
                  </InfoText>
                ) : (
                  <InfoText>
                    Deseja alterar esta reserva para as <b> {editScheduleData.time} </b>
                    com <b>{professionalName}</b> em todos os dias de seu período 
                    ou apenas o dia selecionado ({selectedDate.toFormat ('dd/MM/yyyy')})?
                  </InfoText>
                )
              }
              options={isOneDayPeriod ? [
                {label: 'Sim', onClick: editReservation, dangerous: false},
                {label: 'Não', onClick: toggleEditReservationDialog, dangerous: false}
              ] : [
                {label: 'Alterar reserva', onClick: editReservation, dangerous: false},
                {label: 'Alterar dia', onClick: editReservationDay, dangerous: false}
              ]}
              onClose={toggleEditReservationDialog}
              containerStyle={{width: 400}}
              buttonStyle={!isOneDayPeriod && {width: 160}}
            />
          </>
        )
      }) ()}
      <AlertDialog
        visible={errorMessage !== null}
        title='Erro'
        message={errorMessage}
        confirmText='Ok'
        onConfirm={() => setErrorMessage (null)}
        onClose={() => setErrorMessage (null)}
      />
    </Container>
  )
}

export default SchedulesTable
