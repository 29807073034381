import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ selected }) => selected ? '#F7F7F7' : '#FFFFFF'};
  width: 100%;
  margin: 0px 0px 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const DataGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const FormGrid = styled(DataGrid)`
  align-items: flex-start;
  margin-top: 6px;
  padding-top: 5px;
  border-top: 1px solid #F0F0F0;
  grid-template-columns: 2fr repeat(3, 1fr);
  cursor: auto;
`

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin-top: 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`
