import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import BusinessBillList from '../../../../components/Lists/BusinessBillList'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  Container,
} from '../styles'

function CustomerBills ({ customer }) {
  const [loading, setLoading] = useState (true)
  const [bills, setBills] = useState ([])
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getBills ()
  }, [])

  const getBills = async () => {
    try {
      const { data: bills } = await cutileiApi.get (
        `/business_bills?deleted=false&customer_id=${customer.id}&business_id=${businessId}`,
        requestConfig
      )
      setBills (bills)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <BusinessBillList
          bills={bills}
          containerStyle={{paddingInline: 10}}
          onEdit={bill => setBills (bills.map (b => b.id === bill.id ? {...bill} : b))}
          onDelete={bill => setBills (bills.filter (b => b.id !== bill.id))}
        />
      )}
    </Container>
  )
}

export default CustomerBills
