import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

const getContainerColor = ({
  isInProgress, isCompleted, isValidated, isReservation, cutileiSchedule, status
}) => {
  let statusColor = ''

  switch (status) {
    case 'scheduled': statusColor = '#373737'; break
    case 'confirmed': statusColor = '#89C6C2'; break
    case 'waiting': statusColor = '#FFD860'; break
    case 'in_progress': statusColor = '#ACD685'; break
    case 'finished': statusColor = '#D39CC6'; break
    case 'paid': statusColor = '#52A067'; break
    case 'missed': statusColor = '#DF6969'; break
    case 'canceled': statusColor = '#FF3939'; break
    default: statusColor = '#373737'
  }

  if (cutileiSchedule) {
    if (status === 'canceled')
      return '#FF3939'
    else if (isValidated && isInProgress)
      return '#FFD860'
    else if (isCompleted)
      return isValidated ? '#52A067' : '#DF6969'
    else return '#373737'
  } else {
    return isReservation ? '#A5A5A5' : statusColor
  }
}

export const Container = styled.div`
  background-color: #FFFFFF;
  width: 100%;
  margin: 0px 0px 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  display: grid;
  grid-template-columns: ${({detailed}) => `repeat(${detailed ? 7 : 6}, 1fr)`};
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  cursor: ${({detailed}) => detailed ? 'pointer' : 'default'};

  &:hover {
    background-color: #F7F7F7;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const Image = styled.img`  
  width: 16px;
  height: 16px;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
  text-align: left;
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => {
  return {
    value: props.value.toFixed (2).replace ('.', ','),
    thousandSeparator: '.',
    decimalSeparator: ',',
    displayType: 'text',
    prefix: 'R$ '
  }
})`
  color: ${({value}) => parseFloat (value.replace (',', '.')) >= 0 ? '#44CF6C' : '#FF3939'};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const Light = styled.span`
  height: 14px;
  width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: ${props => getContainerColor (props)};
`
