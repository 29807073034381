import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../services/auth'
import Navbar from '../../components/Navbar'
import CashRegisters from './CashRegisters'
import CashRegisterHistory from './CashRegisterHistory'
import OpenBills from './OpenBills'
import ClosedBills from './ClosedBills'
import Comissions from './Comissions'

import { 
  Container,
  Row,
  TabButton
} from './styles'

function Financial () {
  const [option, setOption] = useState (localStorage.getItem ('financial_option') ?? 'cash_registers')
  const isBusiness = AuthService.isBusiness ()

  const OPTION_LIST = [
    ...isBusiness ? [
      {label: 'Caixas abertos', value: 'cash_registers', component: <CashRegisters/>},
      {label: 'Histórico de caixa', value: 'cash_register_history', component: <CashRegisterHistory/>},
      {label: 'Comandas abertas', value: 'open_bills', component: <OpenBills/>},
      {label: 'Comandas fechadas', value: 'closed_bills', component: <ClosedBills/>},
      {label: 'Comissões', value: 'comissions', component: <Comissions/>},
    ] : [
      {label: 'Caixas abertos', value: 'cash_registers', component: <CashRegisters/>},
      {label: 'Histórico de caixa', value: 'cash_register_history', component: <CashRegisterHistory/>},
      {label: 'Comandas abertas', value: 'open_bills', component: <OpenBills/>},
      {label: 'Comandas fechadas', value: 'closed_bills', component: <ClosedBills/>},
    ]
  ]

  return (
    <Container>
      <Navbar/>
      <Row>
        {OPTION_LIST.map ((o, index) =>
          <TabButton
            key={index}
            selected={option === o.value}
            color={option === o.value ? '#FFC100' : '#FFFFFF'}
            onClick={() => {
              setOption (o.value)
              localStorage.setItem ('financial_option', o.value)
            }}
          >
            {o.label}
          </TabButton>
        )}
      </Row>
      {OPTION_LIST.find (o => o.value === option).component}
    </Container>
  )
}

export default withRouter (Financial)
