import React, { useState, useEffect } from 'react'
import BusinessBill from './BusinessBill'
import EditBillModal from '../../../pages/Financial/EditBillModal'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function BusinessBillList ({
  bills,
  editBillData = null,
  showComissions = false,
  showTotalBills = true,
  type = null,
  category = null,
  paymentMethod = null,
  cardFlag = null,
  bankAccount = null,
  containerStyle = {},
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [billData, setBillData] = useState (editBillData)
  const [showEditBillModal, setShowEditBillModal] = useState (false)

  useEffect (() => {
    if (editBillData) editBill (editBillData)
  }, [editBillData])

  const editBill = bill => {
    setBillData (bill)
    toggleEditBillModal ()
  }

  const toggleEditBillModal = () => setShowEditBillModal (!showEditBillModal) 
  
  return (
    <Container style={containerStyle}>
      {showTotalBills && (
        <SubTitle>
          {bills.length + ' ' + (bills.length === 1 ? 'Resultado' : 'Resultados')}
        </SubTitle>
      )}
      {bills.length > 0 && (
        <LabelRow showComissions={showComissions}>
          <SubTitle>Número</SubTitle>
          <SubTitle>Cliente</SubTitle>
          <SubTitle>Caixa responsável</SubTitle>
          <SubTitle>Abertura</SubTitle>
          <SubTitle>Valor</SubTitle>
          {showComissions && <SubTitle>Comissão</SubTitle>}
          <SubTitle>Ações</SubTitle>
        </LabelRow>
      )}
      {bills.map (bill =>
        <BusinessBill
          key={bill.id}
          data={bill}
          type={type}
          category={category}
          paymentMethod={paymentMethod}
          cardFlag={cardFlag}
          bankAccount={bankAccount}
          showComissions={showComissions}
          onEdit={editBill}
          onDelete={handleDelete}
        />
      )}
      {billData && (
        <EditBillModal
          visible={showEditBillModal}
          data={billData}
          showFreeItemsValue={showComissions}
          onClose={toggleEditBillModal}
          onConfirmEdit={bill => handleEdit (bill)}
          onCloseBill={bill => handleDelete (bill)}
        />
      )}
    </Container>
  )
}

export default BusinessBillList
