import React, { useState, useEffect } from 'react'
import cutileiApi, { apiUrl } from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import CashRegister from '../../../components/CashRegister'
import { DateTime } from 'luxon'
import io from 'socket.io-client'

import {
  ListContainer,
  Row,
  InfoText,
  Input
} from '../styles'

function CashRegisterHistory () {
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = AuthService.getBusinessId ()
  const [loading, setLoading] = useState (false)
  const [date, setDate] = useState (isBusiness ? '' : DateTime.now ().toISODate ()) 
  const [cashRegisters, setCashRegisters] = useState ([])
  const [bankAccounts, setBanksAccounts] = useState ([])
  const [cardFlags, setCardFlags] = useState ([])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (date) getData ()
  }, [date])

  useEffect (() => {
    const socket = io (apiUrl)

    socket.on (`${businessId}_cash_register_opened`, handleEditCashRegister)
    socket.on (`${businessId}_cash_register_closed`, handleEditCashRegister)

    return () => {socket.disconnect ()}
  }, [cashRegisters])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: cashRegisters } = await cutileiApi.get (
        `/cash_registers?business_id=${businessId}&date=${date}`, requestConfig
      )

      const { data: bankAccounts } = await cutileiApi.get (
        `/businesses/${businessId}/bank_accounts`, requestConfig
      )

      const { data: cardFlags } = await cutileiApi.get (
        '/card_flags', requestConfig
      )
  
      setCashRegisters (cashRegisters)
      setBanksAccounts (bankAccounts)
      setCardFlags (cardFlags)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const compareCashRegisters = (a, b) => (
    DateTime.fromISO (a.created_at).toMillis () - DateTime.fromISO (b.created_at).toMillis ()
  )

  const handleEditCashRegister = cashRegister => {
    setCashRegisters ([
      ...cashRegisters.filter (cr => cr.id !== cashRegister.id), cashRegister
    ].sort (compareCashRegisters))
  }

  return (
    <ListContainer>
      <Row style={{marginBottom: 25}}>
        {isBusiness ? (
          <Input
            type='date'
            placeholder='Data de início'
            value={date}
            onChange={e => setDate (e.target.value)}
            style={{width: 180}}
          />
        ) : (
          <Input
            type='text'
            value={DateTime.fromISO (date).toFormat ('dd/MM/yyyy')}
            style={{width: 180, backgroundColor: '#FFFFFF'}}
            disabled
          />
        )}
      </Row>
      {loading ? <Loading/> : (
        cashRegisters.length > 0 ? cashRegisters.map ( cashRegister => (
          <CashRegister
            key={cashRegister.id}
            data={cashRegister}
            cardFlags={cardFlags}
            bankAccounts={bankAccounts}
            onCloseCashRegister={handleEditCashRegister}
            onReopenCashRegister={handleEditCashRegister}
          />
        )) : date && (
          <Row>
            <InfoText>Nenhum caixa encontrado</InfoText>
          </Row>
        )
      )}
    </ListContainer>
  )
}

export default CashRegisterHistory
