import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import NumberInput from '../../../components/Inputs/NumberInput'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import editProductValidator from '../../../validators/editProductValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Input,
  NumberInput as NumericInput,
  Button,
  CloseButton,
  Row,
  Title,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function EditProductModal ({
  visible,
  data: product,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const calculateComissionValue = (price, comissionPercentage) => {
    return price * (comissionPercentage / 100 || 0)
  }

  const calculateComissionPercentage = (price, comissionValue) => {
    return Math.round ((comissionValue / price) * 100)
  }

  const recalcutaleComissionValue = (values, setFieldValue) => {
    const comissionValue = calculateComissionValue (values.price, values.comission_percentage)
    setFieldValue ('professional_comission', comissionValue)
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={editProductValidator}
        initialValues={{
          'id': product.id,
          'nickname': product.nickname,
          'stock': product.stock,
          'price': product.price,
          'comission_percentage': product.comission_percentage * 100,
          'professional_comission': product.comission_percentage * product.price ?? 0,
          'business_comission': product.price - (product.comission_percentage * product.price ?? 0)
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          setSubmitting (true)

          try {
            const data = {...values, comission_percentage: values.comission_percentage / 100}
            const url = `/businesses/${businessId}/products/${values.id}`

            await cutileiApi.put (url, data, requestConfig)

            setSubmitting (false)
            handleConfirm ({...product, ...data})
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {function EditProductForm ({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur, 
          handleSubmit
        }) {
          useEffect (() => {
            recalcutaleComissionValue (values, setFieldValue)
          }, [values.price])

          useEffect (() => {
            setFieldValue ('business_comission', Math.abs (values.price - values.professional_comission))
          }, [values.price, values.comission_percentage, values.professional_comission])

          return (
            <Form onSubmit={handleSubmit}>
              <Title>Editar produto</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row>
                <FormField stretch={2}>
                  <Label htmlFor='nickname' style={{marginBottom: 4}}>
                    Apelido
                  </Label>
                  <Input
                    id='nickname'
                    placeholder='Apelido'
                    value={values.nickname}
                    onChange={handleChange ('nickname')} 
                    onBlur={handleBlur ('nickname')}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='stock'>
                    Qtd. estoque
                  </Label>
                  <NumberInput
                    id='stock'
                    name='stock'
                    value={values.stock}
                    minValue={1}
                    onChange={setFieldValue}
                    style={{width: '100%'}}
                  />
                </FormField>
              </Row>
              <Row style={{alignItems: 'flex-start'}}>
                <FormField>
                  <Label style={{marginBottom: 4}} htmlFor='price'>
                    Preço (R$)
                  </Label>
                  <MoneyInput
                    id='price'
                    name='price'
                    placeholder='Preço'
                    value={values.price}
                    onValueChange={setFieldValue}
                    onBlur={handleBlur ('price')}
                  />
                  {touched.price && errors.price && (
                    <WarningText>
                      {errors.price}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='comission_percentage' style={{marginBottom: 4}}>
                    Comissão (%)
                  </Label>
                  <NumericInput
                    id='comission_percentage'
                    name='comission_percentage'
                    placeholder='Comissão (%)'
                    value={values.comission_percentage}
                    minValue={0}
                    onValueChange={(v, _) => {
                      const value = v.floatValue > 100 ? 100 : v.floatValue
                      setFieldValue ('comission_percentage', value)
                      setFieldValue ('professional_comission', calculateComissionValue (values.price, value))
                    }}
                    onBlur={handleBlur}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='professional_comission'>
                    Valor (profissional)
                  </Label>
                  <MoneyInput
                    id='professional_comission'
                    name='professional_comission'
                    placeholder='Valor líquido'
                    value={values.professional_comission}
                    onValueChange={(name, value) => {
                      const _value = value > values.price ? values.price : value
                      setFieldValue (name, _value)
                      setFieldValue ('comission_percentage', calculateComissionPercentage (values.price, _value))
                    }}
                    onBlur={handleBlur ('professional_comission')}
                    style={{marginTop: 3, color: '#44CF6C', fontSize: 16, fontWeight: 'bold'}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='business_comission'>
                    Valor (salão)
                  </Label>
                  <MoneyInput
                    id='business_comission'
                    name='business_comission'
                    value={values.business_comission}
                    style={{marginTop: 3, color: '#44CF6C', fontSize: 16, fontWeight: 'bold'}}
                    disabled
                  />
                </FormField>
              </Row>
              {errorMessage && (
                <ErrorContainer>
                  <p>{errorMessage}</p>
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default EditProductModal
