import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../../services/cutileiApi'
import AuthService from '../../../../services/auth'
import Comission from '../../../../services/Comission'
import General from '../../../../services/General'
import Export from '../../../../services/Export'
import Navbar from '../../../../components/Navbar'
import ProfessionalCreditList from '../../../../components/Lists/ProfessionalCreditList'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import Logo from '../../../../icons/logo-cutilei-simple.png'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'
import ExcelJS from 'exceljs'

import {
  LabelRow, EarningContainer, ProfessionalContainer, ProfessionalLabelRow, Image
} from './styles'

import { 
  Container,
  InfoText,
  InfoContainer,
  TabButton,
  Row,
  Info,
  SubTitle,
  MoneyTextMask,
  ListContainer
} from '../../styles'

function Total ({ history }) {
  const {
    dateRange, earningType, category, totalEarnings, professionals,
    businessEarnings, professionalEarnings, selectedProfessional
  } = history.location.state 
  
  const startDate = DateTime.fromJSDate (dateRange[0])
  const endDate = DateTime.fromJSDate (dateRange[1])
  const allEarningsSelected = earningType.value === 'all_earnings'
  const allProfessionalsSelected = selectedProfessional.value === 'all_professionals'
  const professionalLabel = allProfessionalsSelected ? 'dos profissionais' : 'do profissional'
  const [loading, setLoading] = useState (false)
  const [earningsByPeriod, setEarningsByPeriod] = useState (history.location.state.earningsByPeriod)
  const [sortedEarnings, setSortedEarnings] = useState ({})
  const [bonuses, setBonuses] = useState ([])
  const [discounts, setDiscounts] = useState ([])
  const [bonusTotal, setBonusTotal] = useState (0)
  const [discountTotal, setDiscountTotal] = useState (0)
  const [earningOption, setEarningOption] = useState ({label: 'Serviços', value: 'services'})
  const [typeOption, setTypeOption] = useState (
    allProfessionalsSelected
      ? {label: 'Total por profissional', value: 'professionals'}
      : allEarningsSelected
        ? {label: 'Comandas do salão', value: 'bill'}
        : earningType
  )
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  
  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const TYPE_OPTION_LIST = [
    allProfessionalsSelected && {
      label: 'Total por profissional', value: 'professionals'
    },
    ['all_earnings', 'bill'].includes (earningType.value) && {
      label: 'Comandas do salão', value: 'bill'
    },
    ['all_earnings', 'cutilei'].includes (earningType.value) && {
      label: 'Agendamentos Cutilei', value: 'cutilei'
    }
  ].filter (o => o)

  const EARNING_OPTION_LIST = [
    {label: 'Serviços', value: 'services'},
    {label: 'Produtos', value: 'products'},
    {label: 'Pacotes', value: 'bundles'},
    !allProfessionalsSelected && {label: 'Caixinhas', value: 'tips'},
    bonuses.length > 0 && !allProfessionalsSelected && {label: 'Bônus', value: 'bonuses'},
    discounts.length > 0 && !allProfessionalsSelected && {label: 'Descontos', value: 'discounts'}
  ].filter (o => o)

  useEffect (() => {
    handleEarningOptionSelected ()
  }, [typeOption, earningOption, earningsByPeriod])

  useEffect (() => {
    setBonuses (earningsByPeriod.filter (earning => earning.type === 'bonus'))
    setDiscounts (earningsByPeriod.filter (earning => earning.type === 'discount'))
  }, [earningsByPeriod])
  
  useEffect (() => {
    setBonusTotal (bonuses.reduce ((total, bonus) => total + bonus.value, 0))
  }, [bonuses])

  useEffect (() => {
    setDiscountTotal (discounts.reduce ((total, bonus) => total + bonus.value, 0))
  }, [discounts])

  const getBonusesAndDiscounts = async () => {
    try {
      setLoading (true)

      if (allProfessionalsSelected && allEarningsSelected) {
        const dateFilters = `&start_date=${startDate.toISODate ()}&end_date=${endDate.toISODate ()}`
          
        const { data: bonuses } = await cutileiApi.get (
          `/credits?business_id=${businessId}&professional_id=${selectedProfessional.value}`
          + dateFilters,
          requestConfig
        )
        
        const { data: discounts } = await cutileiApi.get (
          `/debts?business_id=${businessId}&professional_id=${selectedProfessional.value}`
          + dateFilters,
          requestConfig
        )
  
        const formattedDiscounts = discounts.map (d => ({...d, value: d.value * -1}))
        setBonuses (bonuses)
        setDiscounts (formattedDiscounts)
        return {bonuses, discounts: formattedDiscounts}
      } else {
        return {bonuses, discounts}
      }
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  const handleEarningOptionSelected = async () => {
    let sortedEarnings = {}
    if (typeOption.value === 'cutilei') {
      const cutileiEarnings = earningsByPeriod.filter (earning => earning.type === 'cutilei')
      sortedEarnings = {
        cutilei: sortEarningsByType (cutileiEarnings, cutileiEarnings).map (e => ({
          ...e, cutilei_schedule: true
        }))
      }
    } else if (typeOption.value === 'professionals') {
      const { bonuses, discounts } = await getBonusesAndDiscounts ()
      const bonusTotal = bonuses.reduce ((total, bonus) => total + bonus.value, 0)
      const discountTotal = discounts.reduce ((total, bonus) => total + bonus.value, 0)
      
      sortedEarnings = {
        professionals: [{
          name: 'TOTAL',
          totalEarnings,
          professionalEarnings,
          ...bonuses.length > 0 && {bonusTotal},
          ...discounts.length > 0 && {discountTotal},
          professionalTotalEarnings: professionalEarnings + bonusTotal + discountTotal,
          businessEarnings
        }].concat (professionals.map (professional => {
          const professionalBonuses = bonuses.filter (b => b.professional.id === professional.id)
          const professionalDiscounts = discounts.filter (d => d.professional.id === professional.id)
          const professionalBonusTotal = professionalBonuses.reduce ((total, bonus) => total + bonus.value, 0)
          const professionalDiscountTotal = professionalDiscounts.reduce ((total, discount) => total + discount.value, 0)
          const totalEarnings = Comission.getTotalEarnings (earningsByPeriod, earningType, category, professional)
          const professionalEarnings = Comission.getProfessionalEarnings (earningsByPeriod, earningType, category, professional)
          const professionalTotalEarnings = professionalEarnings + professionalBonusTotal + professionalDiscountTotal
          const businessEarnings = Comission.getBusinessEarnings (earningsByPeriod, earningType, category, professional)
    
          return ({
            name: professional.nickname ?? professional.name.split (' ')[0],
            totalEarnings,
            professionalEarnings,
            ...bonuses.length > 0 && {bonusTotal: professionalBonusTotal},
            ...discounts.length > 0 && {discountTotal: professionalDiscountTotal},
            professionalTotalEarnings,
            businessEarnings
          })
        }))
      }
    } else {
      const businessEarnings = earningsByPeriod.filter (earning => earning.type === 'bill')
      const schedules = []
      const services = []
      const products = []
      const bundles = []
      const tips = []
  
      const serviceFilteredBills = businessEarnings.filter (bill => (
        bill.services?.length > 0 || bill.schedules?.length > 0
      )).map (bill => ({...bill, products, bundles, tips}))
  
      const productFilteredBills = businessEarnings.filter (bill => (
        bill.products.length > 0
      )).map (bill => ({...bill, schedules, services, bundles, tips}))
  
      const bundleFilteredBills = businessEarnings.filter (bill => (
        bill.bundles.length > 0
      )).map (bill => ({...bill, schedules, services, products, tips}))
  
      const tipFilteredBills = businessEarnings.filter (bill => (
        bill.tips.length > 0
      )).map (bill => ({...bill, schedules, services, products, bundles}))
  
      const serviceEarnings = businessEarnings.reduce ((acc, bill) => acc.concat ([
        ...bill.services
        .filter (s => !s.cutilei_service)
        .filter (s => category ? s.category.id === category.value : true), 
        ...bill.schedules
        .filter (s => !s.cutilei_schedule)
        .filter (s => category ? s.service.category.id === category.value : true)
      ]), [])
  
      const productEarnings = businessEarnings.reduce ((acc, bill) => acc.concat (bill.products), [])
      const bundleEarnings = businessEarnings.reduce ((acc, bill) => acc.concat (bill.bundles), [])
      const tipEarnings = businessEarnings.reduce ((acc, bill) => acc.concat (bill.tips), [])
      const filteredBonuses = earningsByPeriod.filter (earning => earning.type === 'bonus')
      const filteredDiscounts = earningsByPeriod.filter (earning => earning.type === 'discount')
  
      sortedEarnings = {
        services: sortEarningsByType (serviceEarnings, serviceFilteredBills),
        products: sortEarningsByType (productEarnings, productFilteredBills),
        bundles: sortEarningsByType (bundleEarnings, bundleFilteredBills),
        tips: sortEarningsByType (tipEarnings, tipFilteredBills),
        bonuses: filteredBonuses,
        discounts: filteredDiscounts
      }
    }
    setSortedEarnings (sortedEarnings)
  }

  const sortEarningsByType = (typeEarnings, typeFilteredBills) => ([
    {
      name: 'TOTAL',
      qtd: typeEarnings.length,
      totalEarnings: Comission.getTotalEarnings (typeFilteredBills, typeOption, category),
      professionalEarnings: Comission.getProfessionalEarnings (typeFilteredBills, typeOption, category),
      businessEarnings: Comission.getBusinessEarnings (typeFilteredBills, typeOption, category)
    },
    ...General.groupBy (typeEarnings.map (e => ({
      ...e, name: e?.name ?? e?.service?.name ?? e?.description
    })), 'name').map (earnings => ({
      name: earnings[0].name,
      qtd: earnings.length,
      totalEarnings: earnings.filter (freeServices).reduce (sumItemsTotal, 0),
      professionalEarnings: earnings.reduce (sumProfessionalItemsTotal, 0),
      businessEarnings: earnings.filter (freeServices).reduce (sumBusinessItemsTotal, 0)
    })).sort ((a, b) => b.qtd - a.qtd)
  ])

  const freeServices = service => !service.bundle_id
  const sumItemsTotal = (total, item) => total + (item.custom_price ?? item.price ?? 0)
  const sumBusinessItemsTotal = (total, item) => (
    total + (item.custom_price ?? item.price ?? item.value) * (
      1 - (item.comission_percentage ?? (item.professional_id ? item.custom_comission_percentage : 0))
    )
  )

  const sumProfessionalItemsTotal = (total, item) => (
    total + (item.discount_price ?? item.custom_price ?? item.price ?? item.value) * (
      item.comission_percentage ?? (item.professional_id ? item.custom_comission_percentage : 0)
    )
  )

  const handleDeleteCredit = credit => {
    const index = EARNING_OPTION_LIST.findIndex (o => o && o.value === earningOption.value)
    const filteredEarnings = earningsByPeriod.filter (e => e.id !== credit.id)
    
    setEarningsByPeriod (filteredEarnings)

    if (!filteredEarnings.some (e => e.type === credit.type))
      setEarningOption (EARNING_OPTION_LIST [index - 1])
  }

  const handleExportSheet = () => {
    const workbook = new ExcelJS.Workbook ()
    const title = typeOption.label
    const startDate = DateTime.fromJSDate (dateRange[0]).toFormat ('dd/MM/yyyy')
    const endDate = DateTime.fromJSDate (dateRange[1]).toFormat ('dd/MM/yyyy')
    const description = `(${startDate} a ${endDate})` + (
      typeOption.value !== 'professionals'? ` - ${selectedProfessional.label}` : ''
    )
    const fileName = `${title} ${description.replace (/\//g, '-')}`
    const earningOptions = typeOption.value === 'bill'
      ? EARNING_OPTION_LIST
      : TYPE_OPTION_LIST.filter (option => option.value === typeOption.value)

    earningOptions.forEach (option => {
      const worksheet = workbook.addWorksheet (option.label)

      switch (option.value) {
        case 'professionals': worksheet.columns = [
          {header: option.label, key: 'name', width: 25},
          {header: 'Total bruto', key: 'totalEarnings', width: 25},
          {header: 'Comissões', key: 'professionalEarnings', width: 25},
          bonuses.length > 0 ? {header: 'Bônus', key: 'bonusTotal', width: 25} : null,
          discounts.length > 0 ? {header: 'Descontos', key: 'discountTotal', width: 25} : null,
          {header: `Total ${professionalLabel}`, key: 'professionalTotalEarnings', width: 25},
          {header: 'Total Líquido', key: 'businessEarnings', width: 25}
        ].filter (o => o); break
        case 'bonuses': 
        case 'discounts': worksheet.columns = [
          {header: 'Data', key: 'date', width: 25},
          {header: 'Descrição', key: 'description', width: 50},
          {header: 'Valor', key: 'value', width: 25}
        ].filter (o => o); break
        default: worksheet.columns = [
          {header: option.label, key: 'name', width: 50},
          {header: 'Quantidade', key: 'qtd', width: 25},
          {header: 'Total bruto', key: 'totalEarnings', width: 25},
          {header: 'Comissões', key: 'professionalEarnings', width: 25},
          {header: 'Total Líquido', key: 'businessEarnings', width: 25}
        ]; break
      }
  
      worksheet.addRows (sortedEarnings[option.value].map (e => ({
        ...e, ...e.date && {date: DateTime.fromISO (e.date).toFormat ('dd/MM/yyyy')}
      })))
  
      worksheet.eachRow ((row, rowNumber) => {
        row.height = rowNumber > 1 ? 20 : 30
        row.eachCell ((cell, colNumber) => {
          cell.font = {name: 'Arial', bold: true, color: {argb: rowNumber > 1 ? '000000' : 'FFFFFF'}}
          cell.alignment = {vertical: 'middle', horizontal: 'center'}
          if (colNumber > 2 || (option.value === 'professionals' && colNumber > 1)) {
            cell.numFmt = '"R$ "#,##0.00;"R$ "#,##0.00'
            if (rowNumber > 1) cell.font.color = {argb: cell.value >= 0 ? '44CF6C' : 'FF3939'}
          }
          if (rowNumber > 1) cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'EEEEEE'}}
          else cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFC100'}}
        })
      })
    })
    
    Export.downloadWorkSheet (workbook, fileName)
  }

  return (
    <Container>
      <Navbar/>
      {loading ? <Loading style={{marginTop: 30}}/> : (
        <>
          <InfoContainer style={{alignItems: 'center'}}>
            <SubTitle style={{marginBottom: 7}}>
              Total do período ({startDate.toFormat ('dd/MM/yyyy')} -
              {endDate.toFormat (' dd/MM/yyyy')}) - {selectedProfessional.label}
            </SubTitle>
            <Info style={{alignItems: 'center', height: 'auto'}}>
              <Row>
                <InfoText>Faturamento bruto:</InfoText>
                <MoneyTextMask value={totalEarnings}/>
              </Row>
              <Row>
                <InfoText>Comissão {professionalLabel}:</InfoText>
                <MoneyTextMask value={professionalEarnings}/>
              </Row>
              {bonuses.length > 0 && (
                <Row>
                  <InfoText>Bônus {professionalLabel}:</InfoText>
                  <MoneyTextMask value={bonusTotal ?? 0}/>
                </Row>
              )}
              {discounts.length > 0 && (
                <Row>
                  <InfoText>Descontos {professionalLabel}:</InfoText>
                  <MoneyTextMask value={discountTotal ?? 0}/>
                </Row>
              )}
              {(bonuses.length > 0 || discounts.length > 0) && (
                <Row>
                  <InfoText>Total {professionalLabel}:</InfoText>
                  <MoneyTextMask value={professionalEarnings + (bonusTotal ?? 0) + (discountTotal ?? 0)}/>
                </Row>
              )}
              <Row>
                <SubTitle>Comissão do salão:</SubTitle>
                <MoneyTextMask value={businessEarnings}/>
              </Row>
            </Info>
          </InfoContainer>
          {(allEarningsSelected || allProfessionalsSelected) && (
            <Row>
              {TYPE_OPTION_LIST.map ((o, index) =>
                <TabButton
                  key={index}
                  selected={typeOption.value === o.value}
                  color={typeOption.value === o.value ? '#FFC100' : '#FFFFFF'}
                  onClick={() => setTypeOption (o)}
                  style={{width: 190}}
                >
                  {o.label}
                </TabButton>
              )}
            </Row>
          )}
          {typeOption.value === 'bill' && (
            <Row>
              {EARNING_OPTION_LIST.map ((o, index) =>
                <TabButton
                  key={index}
                  selected={earningOption.value === o.value}
                  color={earningOption.value === o.value ? '#FFC100' : '#FFFFFF'}
                  onClick={() => setEarningOption (o)}
                >
                  {o.label}
                </TabButton>
              )}
            </Row>
          )}
          <TabButton onClick={handleExportSheet}>
            <FA.FaFileExcel size={14} style={{marginTop: 2, marginRight: 7}}/>
            Exportar
          </TabButton>
          <ListContainer>
            {['bonuses', 'discounts'].includes (earningOption.value) ? (
              <ProfessionalCreditList
                credits={sortedEarnings[earningOption.value]}
                type={earningOption.value === 'bonuses' ? 'bonus' : 'discount'}
                onEdit={credit => {
                  setEarningsByPeriod (earningsByPeriod.map (e => e.id === credit.id ? {...credit} : e))
                }}
                onDelete={handleDeleteCredit}
                styles={{width: 960, minWidth: 840}}
              />
            ) : typeOption.value === 'professionals' ? (
              <>
                <ProfessionalLabelRow bonuses={bonuses} discounts={discounts}>
                  <SubTitle>Profissional</SubTitle>
                  <SubTitle>Total bruto</SubTitle>
                  <SubTitle>Comissões</SubTitle>
                  {bonuses.length > 0 && <SubTitle>Bônus</SubTitle>}
                  {discounts.length > 0 && <SubTitle>Descontos</SubTitle>}
                  <SubTitle>Total do profissional</SubTitle>
                  <SubTitle>Total líquido</SubTitle>
                </ProfessionalLabelRow>
                {sortedEarnings[typeOption.value]?.map ((professional, index) => {
                  const {
                    name, totalEarnings, professionalEarnings, bonusTotal,
                    discountTotal, professionalTotalEarnings, businessEarnings
                  } = professional
                  
                  return (
                    <ProfessionalContainer key={index} bonuses={bonuses} discounts={discounts}>
                      <SubTitle>{name}</SubTitle>
                      <MoneyTextMask value={totalEarnings}/>
                      <MoneyTextMask value={professionalEarnings}/>
                      {bonuses.length > 0 && <MoneyTextMask value={bonusTotal ?? 0}/>}
                      {discounts.length > 0 && <MoneyTextMask value={discountTotal ?? 0}/>}
                      <MoneyTextMask value={professionalTotalEarnings}/>
                      <MoneyTextMask value={businessEarnings}/>
                    </ProfessionalContainer>
                  )
                })}
              </>
            ) : (
              <>
                <LabelRow>
                  <SubTitle>{earningOption.label}</SubTitle>
                  <SubTitle>Qtd.</SubTitle>
                  <SubTitle>Total bruto</SubTitle>
                  <SubTitle>Comissões</SubTitle>
                  <SubTitle>Total líquido</SubTitle>
                </LabelRow>
                {sortedEarnings[
                  typeOption.value === 'bill' ? earningOption.value : typeOption.value
                ]?.map ((earning, index) => (
                  <EarningContainer key={index}>
                    <Row>
                      {earning.cutilei_schedule && <Image src={Logo} alt='Service Cutilei'/>}
                      <SubTitle>
                        {earning?.name ?? earning?.service?.name ?? earning?.description}
                      </SubTitle>
                    </Row>
                    <SubTitle>{earning.qtd}</SubTitle>
                    <MoneyTextMask value={earning.totalEarnings}/>
                    <MoneyTextMask value={earning.professionalEarnings}/>
                    <MoneyTextMask value={earning.businessEarnings}/>
                  </EarningContainer>
                ))}
              </>
            )}
          </ListContainer>
        </>
      )}
    </Container>
  )
}

export default withRouter (Total)
