import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi, { apiUrl } from '../../services/cutileiApi'
import AuthService from '../../services/auth'
import TimeWindow from '../../services/timeWindow'
import Calendar from 'react-calendar'
import ReactTooltip from 'react-tooltip'
import { DateTime } from 'luxon'
import Navbar from '../../components/Navbar'
import ClearableInput from '../../components/Inputs/ClearableInput'
import SchedulesTable from '../../components/SchedulesTable'
import CreateReservationModal from '../Modals/CreateReservationModal'
import ChangePictureModal from '../Modals/ChangePictureModal'
import AlertDialog from '../../components/AlertDialog'
import { ReactComponent as Loading } from '../../icons/loading2.svg'
import io from 'socket.io-client'
import '../../styles/calendar.css'

import {
  Container,
  Row,
  Button,
  SmallButton,
  LinkButton,
  Header,
  ImageButton,
  Image,
  SubTitle,
  Label,
  TooltipLabel,
  LinkText,
  SubContainer,
  LogoContainer,
  CalendarContainer,
  Schedules,
} from './styles'

function Agenda ({ history }) {
  const [date, setDate] = useState (new Date ())
  const [query, setQuery] = useState ('')
  const [professionals, setProfessionals] = useState ([])
  const [schedules, setSchedules] = useState ([])
  const [timeWindows, setTimeWindows] = useState ([])
  const [timeInterval, setTimeInterval] = useState (30)
  const [loading, setLoading] = useState (true)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [showCreateReservationModal, setShowCreateReservationModal] = useState (false)
  const [showPictureDialog, setShowPictureDialog] = useState (false)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    const newSocket = io (apiUrl)

    newSocket.on (`${businessId}_schedule_created`, schedule => handleCreateSchedule ([schedule]))
    newSocket.on (`${businessId}_reservation_created`, handleCreateReservations)
    newSocket.on (`${businessId}_schedule_updated`, handleEditSchedule)
    newSocket.on (`${businessId}_schedule_status_updated`, handleEditScheduleStatus)
    newSocket.on (`${businessId}_schedule_deleted`, handleDeleteSchedule)
    newSocket.on (`${businessId}_professional_removed`, handleRemoveProfessional)

    return () => {newSocket.disconnect ()}
  }, [schedules])

  useEffect (() => {
    getData ()
  }, [date, query])

  const getData = async  (_query = query) => {
    const formattedDate = DateTime.fromJSDate (date).toISODate ()

    setLoading (true)
    try {
      const { data: professionals } = await cutileiApi.get (
        `/businesses/${businessId}/professionals?access_level=service&status=active`,
        requestConfig
      )

      const { data: workingDays } = await cutileiApi.get (`/businesses/${businessId}/working_days`)

      // const { data: services } = await cutileiApi.get (
      //   `/businesses/${businessId}/services?status=active`, 
      //   requestConfig
      // )

      const { data: schedules } = await cutileiApi.get (
        `/schedules?business_id=${businessId}&active=true`
        + `&date=${formattedDate}&page=1&per_page=999&query=${_query}`,
        requestConfig
      )

      const { data: reservations } = await cutileiApi.get (
        `/schedule_reservations?business_id=${businessId}&date=${formattedDate}`,
        requestConfig
      )

      setProfessionals (professionals)
      if (workingDays.length === 0) toggleAlertDIalog ()

      setSchedules ([
        ...schedules.data,
        ...reservations
      ].sort (compareSchedules))

      const currentDay = workingDays.find (day => (
        day.iso_number === DateTime.fromJSDate (date).weekday
      ))
      
      setTimeWindows (currentDay
        ? TimeWindow.getTimeWindows (currentDay.opening, currentDay.closing, timeInterval).map (t => t.value)
        : TimeWindow.getTimeWindows (workingDays[0].opening, workingDays[0].closing, timeInterval).map (t => t.value)
      )
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const compareSchedules = (a, b) => {
    const AstartTime = a.start_time || a.time
    const BstartTime = b.start_time || b.time

    return DateTime.fromISO (AstartTime).toMillis () - DateTime.fromISO (BstartTime).toMillis ()
  }

  const handleChangePicture = newPictureUrl => {
    AuthService.updateUser ({business: {...user, logo: newPictureUrl}})
    togglePictureDialog ()
  }

  const handleCreateSchedule = createdSchedules => {
    if (createdSchedules[0].date === DateTime.fromJSDate (date).toFormat ('yyyy-MM-dd'))
      setSchedules ([...schedules, ...createdSchedules].sort (compareSchedules))
  }

  const handleCreateReservations = reservations => {
    setSchedules ([
      ...schedules,
      ...reservations.filter (r =>
        DateTime.fromISO (r.start_date).startOf ('day') <= DateTime.fromJSDate (date).startOf ('day')
      )
    ].sort (compareSchedules))
  }

  const handleEditSchedule = schedule => {
    const scheduleDate = schedule.date ?? schedule.start_date
    let organizedSchedules = schedules.filter (s => s.id !== schedule.id)

    if (DateTime.fromISO (scheduleDate).startOf ('day') <= DateTime.fromJSDate (date).startOf ('day'))
      organizedSchedules.push (schedule)

    setSchedules (organizedSchedules.sort (compareSchedules))
  }

  const handleEditScheduleStatus = editedSchedules => {
    if (editedSchedules[0]?.date === DateTime.fromJSDate (date).toFormat ('yyyy-MM-dd'))
      setSchedules ([
        ...schedules.filter (schedule => !editedSchedules.some (s => s.id === schedule.id)),
        ...editedSchedules
      ].sort (compareSchedules))
  }

  const handleDeleteSchedule = schedule => setSchedules (schedules.filter (s => s.id !== schedule.id))
  
  const handleDeleteAllSchedules = deletedSchedules => {
    setSchedules (schedules.filter (schedule => !deletedSchedules.some (s => s.id === schedule.id)))
  }

  const handleRemoveProfessional = professional => {
    setProfessionals (professionals.filter (p => p.id !== professional.id))
    setSchedules (schedules.filter (s => s.professional.id !== professional.id))
  }

  const toggleAlertDIalog = () => setShowAlertDialog (!showAlertDialog)
  const toggleCreateReservationModal = () => setShowCreateReservationModal (!showCreateReservationModal)
  const togglePictureDialog = () => setShowPictureDialog (!showPictureDialog)

  return (
    <Container>
      <Navbar/>
      <Header/>
      <SubContainer>
        <CalendarContainer>
          <LogoContainer>
            {isBusiness ? (
              <ImageButton onClick={togglePictureDialog}>
                <Image
                  src={isBusiness ? user.logo : user.profile_picture}
                  alt={user.name}
                  data-for='changePicture'
                  data-tip
                />
                <ReactTooltip
                  id='changePicture'
                  effect='solid'
                  place='right'
                  backgroundColor='#252525'
                  tooltipRadius='10'
                >
                  <TooltipLabel>Alterar foto</TooltipLabel>
                </ReactTooltip>
              </ImageButton>
            ) : (
              <Image src={isBusiness ? user.logo : user.profile_picture} alt={user.name}/>
            )}
            <SubTitle>{user.name}</SubTitle>
          </LogoContainer>
          <Row>
            <ClearableInput
              placeholder='Pesquisar por nome'
              value={query}
              onChange={e => setQuery (e.target.value)}
              onClear={() => {
                setQuery ('')
                getData ('')
              }}
              containerStyle={{width: '100%'}}
              inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
              data-for='search'
              data-tip
            />
            <ReactTooltip
              id='search'
              effect='solid'
              place='right'
              backgroundColor='#252525'
              tooltipRadius='10'
            >
              <TooltipLabel>Pesquisar agendamentos por nome do cliente</TooltipLabel>
            </ReactTooltip>
          </Row>
          <Row>
            <Calendar 
              onChange={setDate}
              value={date}
              calendarType = 'US'
            />
          </Row>
          {isBusiness &&
            <LinkButton to='/business/add-professional'>
              Cadastrar profissional
            </LinkButton>
          }
          <Button onClick={toggleCreateReservationModal}>
            Reservar Agenda
          </Button>
        </CalendarContainer>
        {loading ? (
          <SubContainer style={{marginLeft: 10, zIndex: 6}}>
            <Loading/>
          </SubContainer>
        ) : (
          <Schedules>
            {professionals.length > 0 ? (
              <SchedulesTable
                professionals={professionals}
                schedules={schedules}
                timeWindows={timeWindows}
                timeInterval={timeInterval}
                date={date}
                numRows={timeWindows.length}
                numColumns={professionals.length}
                labels={timeWindows}
                query={query}
                onScheduleCreate={handleCreateSchedule}
                onEditSchedule={handleEditSchedule}
                onDeleteSchedule={handleDeleteSchedule}
                onDeleteAllSchedules={handleDeleteAllSchedules}
                onEditScheduleStatus={handleEditScheduleStatus}
                onRemoveProfessional={handleRemoveProfessional}
              />
            ) : (
              <Label style={{marginLeft: 10}}>
                Clique em "Cadastrar profissional" para começar a cadastrar seus profissionais. 
                Os profissionais aparecerão aqui
              </Label>
            )}
          </Schedules>
        )}
      </SubContainer>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message={
          <Label style={{textAlign: 'center'}}>
            Para que seu salão seja listado, conclua o cadastro dos
            <LinkText to='/edit-agenda'> dias de atendimento e serviços</LinkText>
          </Label>
        }
        dangerous={false}
        onConfirm={() => history.replace ('/business/edit-agenda')}
        onClose={toggleAlertDIalog}
      />
      <CreateReservationModal
        visible={showCreateReservationModal}
        onClose={toggleCreateReservationModal}
        onConfirm={handleCreateReservations}
      />
      <ChangePictureModal
        title='Alterar foto de perfil'
        visible={showPictureDialog}
        onConfirm={handleChangePicture}
        onClose={togglePictureDialog}
      />
    </Container>
  )
}

export default withRouter (Agenda)
