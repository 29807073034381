import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Select from '../../../components/Inputs/Select'
import ClearableInput from '../../../components/Inputs/ClearableInput'
import BusinessServiceList from '../../../components/Lists/BusinessServiceList'
import BusinessServicesModal from '../../Modals/ServicesModal/Business'
import AddServiceModal from '../../Modals/AddServiceModal'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import {
  Container,
  Button,
  Row
} from '../styles'

function EditServices () {
  const [loading, setLoading] = useState (true)
  const [services, setServices] = useState ([])
  const [categories, setCategories] = useState ([])
  const [weekdays, setWeekdays] = useState ([])
  const [category, setCategory] = useState (null)
  const [name, setName] = useState ('')
  const [orderBy, setOrderBy] = useState ('')
  const [showServicesModal, setShowServicesModal] = useState (false)
  const [showAddServiceModal, setShowAddServiceModal] = useState (false)
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  useEffect (() => {
    getData ()
  }, [])

  useEffect (() => {
    getServices ()
  }, [category, name])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getData = async () => {
    try {
      setLoading (true)

      const { data: weekdays } = await cutileiApi.get ('/weekdays')
      const { data: categories } = await cutileiApi.get ('/service_categories', requestConfig)

      setWeekdays (weekdays)
      setCategories (categories.map (category => ({
        label: category.name,
        value: category.id
      })))

      await getServices ()
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const getServices = async () => {
    let filters = ''
    if (name) filters += `&name=${name}`
    if (category) filters += `&category_id=${category.value}`
    if (orderBy) filters += `&order_by=${orderBy}`

    try {
      setLoading (true)

      const { data: services } = await cutileiApi.get (
        `/businesses/${businessId}/services?status=active${filters}`, requestConfig
      )

      setServices (services)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const compareServices = (a, b) => a.number - b.number

  const toggleServicesModal = () => setShowServicesModal (!showServicesModal)
  const toggleAddServiceModal = () => setShowAddServiceModal (!showAddServiceModal)

  return (
    <Container>
      <Row style={{gap: 10, width: 900, marginBottom: 25}}>
        <Select
          name='category'
          placeholder='Categoria...'
          value={category}
          options={categories}
          isClearable={true}
          onChange={(_, option) => setCategory (option)}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, height: 38}}
        />
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={name}
          onChange={e => setName (e.target.value)}
          onClear={() => setName ('')}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
        <Button onClick={toggleServicesModal} style={{width: '45%'}}>
          Adicionar serviços
        </Button>
        <Button onClick={toggleAddServiceModal} style={{width: '45%'}}>
          Cadastrar serviço
        </Button>
      </Row>
      {loading ? <Loading/> : (
        <>
          <BusinessServiceList
            services={services}
            weekdays={weekdays}
            onEdit={service => setServices (services.map (s => s.id === service.id ? {...service} : s))}
            onDelete={deletedService => setServices (services.filter (s => s.id !== deletedService.id))}
          />
          <BusinessServicesModal
            visible={showServicesModal}
            onConfirm={addedServices => setServices (
              [...services, ...addedServices].sort (compareServices)
            )}
            onClose={toggleServicesModal}
          />
          <AddServiceModal
            visible={showAddServiceModal}
            serviceCategories={categories}
            weekdays={weekdays}
            onConfirm={service => setServices ([service, ...services])}
            onClose={toggleAddServiceModal}
          />
        </>
      )}
    </Container>
  )
}

export default EditServices
