import React, { useState, useEffect, Fragment } from 'react'
import cutileiApi, { apiUrl } from '../../services/cutileiApi'
import AuthService from '../../services/auth'
import Payment from '../../services/Payment'
import Comission from '../../services/Comission'
import AddEarningModal from '../../pages/Financial/AddEarningModal'
import AddExpenseModal from '../../pages/Financial/AddExpenseModal'
import BusinessBillsModal from '../../pages/Modals/BusinessBillsModal'
import SchedulesModal from '../../pages/Modals/SchedulesModal'
import Earning from '../Earning'
import Expense from '../Expense'
import AlertDialog from '../AlertDialog'
import { ReactComponent as Loading } from '../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../icons/loading.svg'
import * as FA from 'react-icons/fa'
import io from 'socket.io-client'
import { DateTime } from 'luxon'

import {
  InfoContainer,
  ErrorContainer,
  RegisterColumn,
  Info,
  Row,
  HorizontalLine,
  InfoRow,
  InfoLink,
  Title,
  SubTitle,
  InfoText,
  Button,
  MoneyTextMask,
} from './styles'

function CashRegister ({
  data: cashRegister,
  cardFlags,
  bankAccounts,
  onReopenCashRegister: handleReopenCashRegister = () => null,
  onCloseCashRegister: handleCloseCashRegister = () => null
}) {
  const [loading, setLoading] = useState (false)
  const [submitting, setSubmitting] = useState (false)
  const [earnings, setEarnings] = useState (cashRegister.earnings)
  const [expenses, setExpenses] = useState (cashRegister.expenses)
  const [payments, setPayments] = useState (cashRegister.payments)
  const [paymentMethod, setPaymentMethod] = useState (null)
  const [bankAccount, setBankAccount] = useState (null)
  const [cardFlag, setCardFlag] = useState (null)
  const [cutileiSchedules, setCutileiSchedules] = useState ([])
  const [paymentsByPaymentMethod, setPaymentsByPaymentMethod] = useState ([])
  const [fitleredPaymentsByPaymentMethod, setFilteredPaymentsByPaymentMethod] = useState ([])
  const [cutileiPaymentTotal, setCutileiPaymentTotal] = useState (0)
  const [paymentTotal, setPaymentTotal] = useState (0)
  const [showAddEarningModal, setShowAddEarningModal] = useState (false)
  const [showAddExpenseModal, setShowAddExpenseModal] = useState (false)
  const [showBusinessBillsModal, setShowBusinessBillsModal] = useState (false)
  const [showSchedulesModal, setShowSchedulesModal] = useState (false)
  const [showOpenCashDialog, setShowOpenCashDialog] = useState (false)
  const [showCloseCashDialog, setShowCloseCashDialog] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const paymentMethodsWithBankAccount = ['pix', 'transfer', 'billet']
  const cardPaymentMethods = ['credit_card', 'debit_card']
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()
  const businessId = cashRegister.business.id

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
    const socket = io (apiUrl)

    socket.on (`${cashRegister.id}_payment_added`, handlePaymentAdded)
    socket.on (`${cashRegister.id}_payment_deleted`, handlePaymentDeleted)
    socket.on (`${cashRegister.id}_earning_created`, handleEarningAdded)
    socket.on (`${cashRegister.id}_expense_created`, handleExpenseAdded)
    socket.on (`${cashRegister.id}_earning_deleted`, handleEarningDeleted)
    socket.on (`${cashRegister.id}_expense_deleted`, handleExpenseDeleted)
    socket.on (`${businessId}_cutilei_schedule_validated`, handleCutileiSchedulesAdded)

    return () => {socket.disconnect ()}
  }, [cashRegister])

  useEffect (() => {
    sortAndCalculatePayments ()
  }, [payments, cutileiSchedules])

  const comparePayments = (a, b) => {
    const methodOrder = {cash: 1, pix: 2, debit_card: 3, credit_card: 4, other: 5}
    const paymentMethodA = methodOrder[a.payment_method.codename] || methodOrder['other']
    const paymentMethodB = methodOrder[b.payment_method.codename] || methodOrder['other']

    return paymentMethodA - paymentMethodB
  }

  const getData = async () => {
    try {
      setLoading (true)
      const date = DateTime.fromISO (cashRegister.created_at).toISODate ()
      const scheduleFilters = `business_id=${businessId}&status=validated&cutilei_schedules=true`
                            + `&date=${date}&page=1&per_page=999`

      const { data: schedules } = await cutileiApi.get (
        `/schedules?${scheduleFilters}`, requestConfig
      )

      const cutileiSchedules = schedules.data.map (s => ({...s, type: 'cutilei'}))

      setCutileiSchedules (cutileiSchedules)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const sortAndCalculatePayments = () => {
    const sortedPayments = payments.sort (comparePayments)
    const cutileiPaymentTotal = Comission.getTotalEarnings (
      cutileiSchedules, {value: 'cutilei'}
    )

    setCutileiPaymentTotal (cutileiPaymentTotal)
    setPaymentTotal (Payment.getTotalValue (payments.filter (p => (
      p.payment_method.count_as_earning === true
    ))) + cutileiPaymentTotal)

    setPaymentsByPaymentMethod (Payment.groupBy (sortedPayments.filter (p => (
      p.payment_method.count_as_earning === true
    )).map (payment => ({
      ...payment, payment_method_codename: payment.payment_method.codename
    })), 'payment_method_codename'))

    setFilteredPaymentsByPaymentMethod (Payment.groupBy (sortedPayments.filter (p => (
      p.payment_method.count_as_earning === false
    )).map (payment => ({
      ...payment,
      payment_method_codename: payment.payment_method.codename,
      value: payment.value * (payment.payment_method.codename === 'debt' ? -1 : 1),
    })), 'payment_method_codename'))
  }

  const reopenCashRegister = async () => {
    setSubmitting (true)
    try {
      const { data: editedCashRegister } = await cutileiApi.put (
        `/cash_registers/${cashRegister.id}`,
        {open: true},
        requestConfig
      )
      setSubmitting (false)
      handleReopenCashRegister (editedCashRegister)
    } catch (error) {
      setSubmitting (false)
      if (error.response.data) setErrorMessage (error.response.data.message)
      console.log (error)
    }
  }

  const closeCashRegister = async () => {
    setSubmitting (true)
    try {
      const { data: editedCashRegister } = await cutileiApi.put (
        `/cash_registers/${cashRegister.id}`,
        {open: false},
        requestConfig
      )
      setSubmitting (false)
      handleCloseCashRegister (editedCashRegister)
    } catch (error) {
      setSubmitting (false)
      if (error.response.data) setErrorMessage (error.response.data.message)
      console.log (error)
    }
  }

  const calculateCashValue = () => {
    const cashPayments = payments.filter (p => p.payment_method.codename === 'cash')
    const balance = Payment.getTotalValue (earnings) - Payment.getTotalValue (expenses)

    return cashRegister.initial_cash_value + balance + Payment.getTotalValue (cashPayments)
  }

  const handleEarningAdded = earning => setEarnings ([...earnings, earning])
  const handleExpenseAdded = expense => setExpenses ([...expenses, expense])
  const handleEarningDeleted = earning => setEarnings (earnings.filter (e => e.id !== earning.id))
  const handleExpenseDeleted = expense => setExpenses (expenses.filter (e => e.id !== expense.id))
  const handlePaymentAdded = payment => setPayments ([...payments, payment])
  const handlePaymentDeleted = payment => setPayments (payments.filter (p => p.id !== payment.id))
  const handleCutileiSchedulesAdded = schedules => {
    if (schedules[0].date === DateTime.fromISO (cashRegister.created_at).toISODate ())
      setCutileiSchedules ([...cutileiSchedules, ...schedules.map (s => ({...s, type: 'cutilei'}))])
  }

  const toggleAddEarningModal = () => setShowAddEarningModal (!showAddEarningModal)
  const toggleAddExpenseModal = () => setShowAddExpenseModal (!showAddExpenseModal)
  const toggleBusinessBillsModal = () => setShowBusinessBillsModal (!showBusinessBillsModal)
  const toggleSchedulesModal = () => setShowSchedulesModal (!showSchedulesModal)
  const toggleOpenCashDialog = () => setShowOpenCashDialog (!showOpenCashDialog)
  const toggleCloseCashDialog = () => setShowCloseCashDialog (!showCloseCashDialog)

  return (
    <InfoContainer>
      <Title>{cashRegister.open ? 'Caixa aberto' : 'Caixa fechado'}</Title>
      {loading ? <Loading/> : (
        <Info>
          <Row style={{alignItems: 'flex-start', marginTop: 10}}>
            <RegisterColumn style={{borderRight: '1px solid #E0E0E0'}}>
              <Row>
                <SubTitle>Responsável: </SubTitle>
                <InfoText>{cashRegister.opened_by?.name ?? user.name}</InfoText>
              </Row>
              <Row>
                <SubTitle>Abertura: </SubTitle>
                <InfoText>
                  {DateTime.fromISO (cashRegister.created_at).toFormat ('dd/MM/yyyy - HH:mm:ss')}
                </InfoText>
              </Row>
              {cashRegister.closed_at && (
                <Row>
                  <SubTitle>Fechamento: </SubTitle>
                  <InfoText>
                    {DateTime.fromISO (cashRegister.closed_at).toFormat ('dd/MM/yyyy - HH:mm:ss')}
                  </InfoText>
                </Row>
              )}
              <Row>
                <SubTitle>Data modificação: </SubTitle>
                <InfoText>
                  {DateTime.fromISO (cashRegister.updated_at).toFormat ('dd/MM/yyyy - HH:mm:ss')}
                </InfoText>
              </Row>
              {cashRegister.updated_by && (
                <Row>
                  <SubTitle>Última modificação: </SubTitle>
                  <InfoText>{cashRegister.updated_by.name}</InfoText>
                </Row>
              )}
              <Row>
                <SubTitle>Valor inicial (Dinheiro): </SubTitle>
                <MoneyTextMask value={cashRegister.initial_cash_value}/>
              </Row>
              <Row>
                <SubTitle>Valor inicial (Cheque): </SubTitle>
                <MoneyTextMask value={cashRegister.initial_check_value}/>
              </Row>
            </RegisterColumn>
            <RegisterColumn style={{paddingLeft: 5}}>
              <SubTitle style={{marginBottom: 15}}>
                Sangrias e suprimentos
              </SubTitle>
              {earnings.length === 0 && expenses.length === 0 ? (
                <Row>
                  <InfoText>Nenhuma movimentação</InfoText>
                </Row>
              ) : (
                <>
                  {earnings.map (earning => (
                    <Earning
                      key={earning.id}
                      data={earning}
                      enableEdit={cashRegister.open}
                      onDelete={handleEarningDeleted}
                    />
                  ))}
                  {expenses.map (expense => (
                    <Expense
                      key={expense.id}
                      data={expense}
                      enableEdit={cashRegister.open}
                      onDelete={handleExpenseDeleted}
                    />
                  ))}
                  <HorizontalLine/>
                  <InfoRow>
                    <SubTitle>TOTAL: </SubTitle>
                    <MoneyTextMask
                      value={(Payment.getTotalValue (earnings) - Payment.getTotalValue (expenses))}
                    />
                  </InfoRow>
                </>
              )}
              {cashRegister.open && (
                <Row style={{marginTop: 20}}>
                  <Button onClick={toggleAddEarningModal}>
                    <FA.FaPlus size={12} style={{marginTop: 2, marginRight: 7}}/>
                    Suprimento
                  </Button>
                  <Button onClick={toggleAddExpenseModal}>
                    <FA.FaPlus size={12} style={{marginTop: 2, marginRight: 7}}/>
                    Sangria
                  </Button>
                </Row>
              )}
            </RegisterColumn>
          </Row>
          <HorizontalLine style={{margin: 0}}/>
          <Row>
            <RegisterColumn>
              <SubTitle style={{marginBottom: 15}}>
                Total em caixa
              </SubTitle>
              <InfoRow>
                <SubTitle>Dinheiro: </SubTitle>
                <MoneyTextMask value={calculateCashValue ()}/>
              </InfoRow>
              <InfoRow>
                <InfoText>Sangria/suprimento: </InfoText>
                <MoneyTextMask
                  value={Payment.getTotalValue (earnings) - Payment.getTotalValue (expenses)}
                />
              </InfoRow>
              <InfoRow>
                <InfoText>Valor Inicial: </InfoText>
                <MoneyTextMask value={cashRegister.initial_cash_value}/>
              </InfoRow>
              {Object.keys (paymentsByPaymentMethod).map ((paymentMethod, index) => {
                const paymentMethodTotalValue = Payment.getTotalValue (paymentsByPaymentMethod[paymentMethod])

                return (
                  <Fragment key={index}>
                    {paymentMethod !== 'cash' && <HorizontalLine/>}
                    {paymentMethodTotalValue > 0 && (
                      <InfoLink onClick={() => {
                        setPaymentMethod (paymentsByPaymentMethod[paymentMethod][0].payment_method)
                        setCardFlag (null)
                        toggleBusinessBillsModal ()
                      }}>
                        {paymentMethod === 'cash' ? (
                          <InfoText>Comandas (dinheiro):</InfoText>
                        ) : (
                          <SubTitle>
                            {paymentsByPaymentMethod[paymentMethod][0].payment_method.name}:
                          </SubTitle>
                        )}
                        <MoneyTextMask value={paymentMethodTotalValue}/>
                      </InfoLink>
                    )}
                    {cardPaymentMethods.includes (paymentMethod) && (
                      cardFlags.map (cardFlag => {
                        const cardFlagTotalValue = Payment.getTotalValueByCardFlag (
                          paymentsByPaymentMethod[paymentMethod], cardFlag.codename
                        )

                        return cardFlagTotalValue !== 0 && (
                          <InfoLink key={cardFlag.id} onClick={() => {
                            setCardFlag (cardFlag)
                            setPaymentMethod (paymentsByPaymentMethod[paymentMethod][0].payment_method)
                            toggleBusinessBillsModal ()
                          }}>
                            <InfoText>{cardFlag.name}: </InfoText>
                            <MoneyTextMask value={cardFlagTotalValue}/>
                          </InfoLink>
                        )
                      })
                    )}
                    {paymentMethodsWithBankAccount.includes (paymentMethod) && (
                      bankAccounts.map (bankAccount => {
                        const bankAccountTotalValue = Payment.getTotalValueByBankAccount (
                          paymentsByPaymentMethod[paymentMethod], bankAccount
                        )

                        return bankAccountTotalValue !== 0 && (
                          <InfoLink key={bankAccount.id} onClick={() => {
                            setBankAccount (bankAccount)
                            setPaymentMethod (paymentsByPaymentMethod[paymentMethod][0].payment_method)
                            toggleBusinessBillsModal ()
                          }}>
                            <InfoText>{bankAccount.description}: </InfoText>
                            <MoneyTextMask value={bankAccountTotalValue}/>
                          </InfoLink>
                        )
                      })
                    )}
                  </Fragment>
                )
              })}
              <HorizontalLine/>
              <InfoLink onClick={toggleSchedulesModal}>
                <SubTitle>Cutilei: </SubTitle>
                <MoneyTextMask value={cutileiPaymentTotal}/>
              </InfoLink>
              <HorizontalLine/>
              <InfoRow>
                <SubTitle>TOTAL FATURADO: </SubTitle>
                <MoneyTextMask value={paymentTotal}/>
              </InfoRow>
              {Object.keys (fitleredPaymentsByPaymentMethod).map ((paymentMethod, index) => (
                <InfoLink key={index} onClick={() => {
                  setPaymentMethod (fitleredPaymentsByPaymentMethod[paymentMethod][0].payment_method)
                  setCardFlag (null)
                  toggleBusinessBillsModal ()
                }}>
                  <InfoText>
                    {fitleredPaymentsByPaymentMethod[paymentMethod][0].payment_method.name}:
                  </InfoText>
                  <MoneyTextMask value={
                    Payment.getTotalValue (fitleredPaymentsByPaymentMethod[paymentMethod])
                  }/>
                </InfoLink>
              ))}
            </RegisterColumn>
          </Row>
          <Row style={{justifyContent: 'flex-end', marginTop: 15}}>
            {cashRegister.open ? (
              <Button style={{backgroundColor: '#FF3939'}} onClick={toggleCloseCashDialog}>
                {submitting ? <ButtonLoading/> : 'Fechar caixa'}
              </Button>
            ) : (
              <Button onClick={toggleOpenCashDialog}>
                {submitting ? <ButtonLoading/> : 'Reabrir caixa'}
              </Button>
            )}
          </Row>
        </Info>
      )}
      <AddEarningModal
        visible={showAddEarningModal}
        cashRegister={cashRegister}
        onConfirm={handleEarningAdded}
        onClose={toggleAddEarningModal}
      />
      <AddExpenseModal
        visible={showAddExpenseModal}
        cashRegister={cashRegister}
        onConfirm={handleExpenseAdded}
        onClose={toggleAddExpenseModal}
      />
      {(paymentMethod || cardFlag || bankAccount) && (
        <BusinessBillsModal
          visible={showBusinessBillsModal}
          date={DateTime.fromISO (cashRegister.created_at).toISODate ()}
          paymentMethod={paymentMethod}
          bankAccount={bankAccount}
          cardFlag={cardFlag}
          onClose={toggleBusinessBillsModal}
        />
      )}
      {cutileiSchedules.length > 0 && (
        <SchedulesModal
          visible={showSchedulesModal}
          schedules={cutileiSchedules}
          onClose={toggleSchedulesModal}
        />
      )}
      {errorMessage && (
        <ErrorContainer>
          <InfoText style={{color: '#FFFFFF'}}>{errorMessage}</InfoText>
        </ErrorContainer>
      )}
      <AlertDialog
        visible={showCloseCashDialog}
        title='Atenção!'
        message='Deseja realmente fechar este caixa?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={closeCashRegister}
        onClose={toggleCloseCashDialog}
      />
      <AlertDialog
        visible={showOpenCashDialog}
        title='Atenção!'
        message='Deseja realmente reabrir este caixa?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={reopenCashRegister}
        onClose={toggleOpenCashDialog}
      />
    </InfoContainer>
  )
}

export default CashRegister
