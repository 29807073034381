import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import NumberInput from '../../../components/Inputs/NumberInput'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import Select from '../../../components/Inputs/Select'
import CheckBox from '../../../components/CheckBox'
import { Formik } from 'formik'
import bundleValidator from '../../../validators/bundleValidator'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Header,
  Form,
  FormField,
  Row,
  Title,
  SubTitle,
  Label,
  WarningText,
  Input,
  NumberInput as NumericInput,
  Button,
  SmallButton,
  DangerButton,
  CloseButton
} from './styles'

function EditBundleModal ({
  visible,
  data: bundle,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [loading, setLoading] = useState (true)
  const [businessServices, setBusinessServices] = useState ([])
  const [indefinitePeriod, setIndefinitePeriod] = useState (bundle.valid_period === 0)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const serviceOptions = businessServices.map (service => ({
    label: service.name,
    value: service
  }))
  
  const comissionTypeOptions = [
    {label: 'Comissão sobre o valor original', value: 'original_value'},
    {label: 'Comissão sobre o valor com desconto', value: 'bundle_value'},
    {label: 'Sem comissão', value: 'no_comission'}
  ]

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: businessServices } = await cutileiApi.get (
        `/businesses/${businessId}/services?status=active&cutilei_services=false`,
        requestConfig
      )

      setBusinessServices (businessServices)
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const handleAddService = (services, setFieldValue) => {
    setFieldValue ('services', [
      ...services,
      {
        id: '',
        name: '',
        price: 0,
        amount: 1
      }
    ])
  }

  const handleDeleteService = (services, index, setFieldValue) => {
    setFieldValue (
      'services',
      services.map ((s, i) => ({...s, idx: i})).filter (s => s.idx !== index)
    )
  }

  const getBundlePrice = bundle => {
    return bundle?.services
      .map (service => service.full_price * service.amount)
      .reduce ((total, value) => total + value)
  }

  const calculateBundlePrice = (values, setFieldValue) => {
    const bundlePrice = values.services.reduce ((total, service) => (
      total + (service.price * service.amount)
    ), 0)

    setFieldValue ('price', bundlePrice)
  }
  
  const calculateDiscountPrice = (price, discountPercentage) => (
    Math.round (price * (1 - (discountPercentage / 100 || 0)))
  )
  
  const calculateDiscountPercentage = (price, discountPrice) => (
    Math.round ((1 - (discountPrice / price)) * 100)
  )

  const recalcutaleDiscountPercentage = (values, setFieldValue) => {
    const discountPercentage = calculateDiscountPercentage (values.price, values.discountPrice)
    setFieldValue ('discountPercentage', discountPercentage)
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      {loading && businessServices.length === 0 ? (
        <Loading/>
      ) : (
        <Formik
          validationSchema={bundleValidator}
          initialValues={{
            'name': bundle.name,
            'price': getBundlePrice (bundle),
            'discountPrice': getBundlePrice (bundle) === bundle.price ? '' : bundle.price,
            'discountPercentage': function () {
              const bundleCalculatedPrice = getBundlePrice (bundle)
              return bundleCalculatedPrice === bundle.price
                ? 0
                : calculateDiscountPercentage (bundleCalculatedPrice, bundle.price)
            } (),
            'comissionPercentage': bundle.comission_percentage * 100,
            'comissionType': comissionTypeOptions.find (o => o.value === bundle.comission_type),
            'services': bundle.services.map (service => ({
              id: service.id,
              name: service.name,
              price: service.full_price,
              amount: service.amount
            })),
            'validPeriod': bundle.valid_period
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const discountPrice = values.discountPrice || values.price
            const bundleData = {
              ...values,
              price: discountPrice,
              comissionPercentage: values.comissionPercentage / 100,
              comissionType: values.comissionType.value,
              services: values.services.map (service => ({
                ...service,
                full_price: service.price,
                discount_price: (
                  ((service.price * service.amount) / values.price) * discountPrice
                ) / service.amount
              }))
            }

            try {
              const { data: editedBundle } = await cutileiApi.put (
                `/bundles/${bundle.id}`, bundleData, requestConfig
              )

              setSubmitting (false)
              handleConfirm (editedBundle)
              handleClose ()
            } catch (error) {
              setSubmitting (false)
              console.log (error)
            }
          }}
        >
          {function EditBundleForm ({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit
          }) {
            useEffect (() => {
              calculateBundlePrice (values, setFieldValue)
            }, [values.services])

            useEffect (() => {
              recalcutaleDiscountPercentage (values, setFieldValue)
            }, [values.price])

            return (
              <Form onSubmit={handleSubmit}>
                <Header>
                  <Title>Editar pacote</Title>
                  <CloseButton onClick={handleClose}>
                    <FA.FaTimes color='#FF3939' size={18}/>
                  </CloseButton>
                </Header>
                <Row style={{marginBottom: indefinitePeriod ? 22 : -10}}>
                  <FormField stretch={3}>
                    <Label htmlFor='name'>
                      Nome
                    </Label>
                    <Input
                      id='name'
                      placeholder='Nome do pacote'
                      value={values.name}
                      onChange={handleChange ('name')} 
                      onBlur={handleBlur ('name')}
                    />
                    {touched.name && errors.name && (
                      <WarningText>
                        {errors.name}
                      </WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <Label htmlFor='validPeriod'>
                      Validade (dias)
                    </Label>
                    {!indefinitePeriod && (
                      <>
                        <NumberInput
                          id='validPeriod'
                          name='validPeriod'
                          value={values.validPeriod}
                          minValue={1}
                          maxValue={366}
                          onChange={setFieldValue}
                          style={{marginRight: 10, marginTop: -3}}
                        />
                        {touched.validPeriod && errors.validPeriod && (
                          <WarningText>
                            {errors.validPeriod}
                          </WarningText>
                        )}
                      </>
                    )}
                    <Row style={{gap: 0, marginRight: 10, marginTop: indefinitePeriod ? 8 : -2}}>
                      <CheckBox
                        id={'indefinite_valid_period'}
                        style={{marginRight: 6, marginTop: 3}}
                        value={indefinitePeriod}
                        onValueChange={value => {
                          setIndefinitePeriod (value)
                          setFieldValue ('validPeriod', value === true ? 0 : 30)
                        }}
                      />
                      <Label htmlFor={'indefinite_valid_period'}>
                        Indeterminado
                      </Label>
                    </Row>
                  </FormField>
                </Row>
                <SubTitle>
                  Serviços inclusos no pacote
                </SubTitle>
                {values.services.map ((_, index) => (
                  <Row key={index} style={{alignItems: 'center', marginBottom: 5}}>
                    <FormField stretch={2}>
                      <Select
                        name={`services[${index}].name`}
                        placeholder='Serviços...'
                        value={values.services[index].name ? {
                          label: values.services[index].name,
                          value: values.services[index].id
                        }: null}
                        options={serviceOptions}
                        onChange={(_, option) => {
                          setFieldValue (`services[${index}]`, {
                            ...values.services[index],
                            name: option.label,
                            id: option.value.id,
                            price: option.value.price
                          })
                        }}
                        onBlur={setFieldTouched}
                        error={errors.services?.[index]?.name}
                        touched={touched.services?.[index]?.name}
                      />
                      {touched.services?.[index]?.name && errors.services?.[index]?.name && (
                        <WarningText>
                          {errors.services[index].name}
                        </WarningText>
                      )}
                    </FormField>
                    <NumberInput
                      name={`services[${index}].amount`}
                      value={values.services[index].amount}
                      minValue={1}
                      onChange={setFieldValue}
                      style={{marginRight: 10}}
                    />
                    {values.services.length > 1 && (
                      <DangerButton
                        type='button'
                        onClick={() => handleDeleteService (values.services, index, setFieldValue)}
                      >
                        <FA.FaTrash color='#FFFFFF' size={12} style={{marginBottom: 2}}/>
                      </DangerButton>
                    )}
                  </Row>
                ))}
                <SmallButton
                  type='button'
                  style={{width: 120}}
                  onClick={() => handleAddService (values.services, setFieldValue)}
                >
                  <FA.FaPlus style={{marginRight: 7}}/>
                  Serviço
                </SmallButton>
                {values.services[0].id && (
                  <>
                    <SubTitle>
                      Preços e comissões
                    </SubTitle>
                    <Row>
                      <FormField>
                        <Label htmlFor='price'>
                          Preço (R$)
                        </Label>
                        <MoneyInput
                          id='price'
                          name='price'
                          placeholder='Preço'
                          value={values.price}
                          onValueChange={setFieldValue}
                          onBlur={handleBlur ('price')}
                          disabled
                        />
                      </FormField>
                      <FormField>
                        <Label htmlFor='discountPrice'>
                          Preço com desconto (R$)
                        </Label>
                        <MoneyInput
                          id='discountPrice'
                          name='discountPrice'
                          placeholder='Preço com desconto'
                          value={values.discountPrice}
                          onValueChange={(name, value) => {
                            setFieldValue (name, value)
                            setFieldValue (
                              'discountPercentage',
                              calculateDiscountPercentage (values.price, value)
                            )
                          }}
                          onBlur={e => {
                            if (parseFloat (e.target.value.split (' ')[1]) > values.price) 
                              setFieldValue ('discountPrice', values.price)
                            handleBlur ('discountPrice')
                          }}
                        />
                        {touched.discountPrice && errors.discountPrice && (
                          <WarningText>
                            {errors.discountPrice}
                          </WarningText>
                        )}
                      </FormField>
                      <FormField>
                        <Label htmlFor='discountPercentage'>
                          Desconto (%)
                        </Label>
                        <NumericInput
                          id='discountPercentage'
                          name='discountPercentage'
                          placeholder='Desconto'
                          value={values.discountPercentage}
                          max={100}
                          onValueChange={(v, _) => {
                            setFieldValue ('discountPercentage', v.floatValue)
                            setFieldValue (
                              'discountPrice',
                              calculateDiscountPrice (values.price, v.floatValue)
                            )
                          }}
                          onBlur={handleBlur}
                          style={{width: '100%'}}
                        />
                      </FormField>
                    </Row>
                    <Row>       
                      <FormField>
                        <Label style={{marginBottom: 1}} htmlFor='comissionPercentage'>
                          Comissão de venda (%)
                        </Label>
                        <NumberInput
                          id='comissionPercentage'
                          name='comissionPercentage'
                          value={values.comissionPercentage}
                          minValue={0}
                          maxValue={100}
                          onChange={setFieldValue}
                          style={{width: '100%', marginLeft: -10}}
                        />
                      </FormField>
                      <FormField stretch={2} style={{alignSelf: 'flex-end', marginBottom: 4}}>
                        <Label htmlFor='comissionType'>
                          Modelo de comissão dos serviços
                        </Label>
                        <Select
                          name='comissionType'
                          placeholder='Selecionar...'
                          value={values.comissionType}
                          options={comissionTypeOptions}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.comissionType}
                          touched={touched.comissionType}
                        />
                        {touched.comissionType && errors.comissionType && (
                          <WarningText>
                            {errors.comissionType}
                          </WarningText>
                        )}
                      </FormField>
                    </Row>
                  </>
                )}
                <Button type='submit' disabled={isSubmitting} style={{marginTop: 10}}>
                  {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
                </Button>
              </Form>
            )
          }}
        </Formik>
      )}
    </Modal>
  )
}

export default EditBundleModal
